import React, { useEffect, useState } from 'react';
import { API_BASE_URL } from '../config';
import { getStorage, ref, getDownloadURL } from "firebase/storage";

import {
  Container, Typography, Paper, Button, Box, IconButton, Grid, Divider,
  CircularProgress, ImageList, ImageListItem, ImageListItemBar,
  Dialog, DialogContent, DialogTitle, List, ListItem, ListItemText,
  Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel,
  Drawer, Avatar, Menu, MenuItem
} from '@mui/material';
import { Link as MuiLink } from '@mui/material';
import PageReviewModal from '../components/PageReviewModal';
import ReactMarkdown from 'react-markdown';
import { Link } from 'react-router-dom';
import { useSearchParams, useNavigate } from 'react-router-dom';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import MermaidDiagram from '../components/MermaidDiagram';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { formatDate } from '../components/FormatDates';
import MenuIcon from '@mui/icons-material/Menu';
import HomeLogo from "../static/img/Transparent_SRD_Logo.png";
import { useAuth } from '../AuthContext';
import { getAuth, signOut } from 'firebase/auth';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import InfoIcon from '@mui/icons-material/Info';


// Extend dayjs with customParseFormat plugin
dayjs.extend(customParseFormat);

const Report = () => {
  const [searchParams] = useSearchParams();
  const report_id = searchParams.get('report_id');
  const [reportData, setReportData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openScreenshot, setOpenScreenshot] = useState(null);
  const [imageErrors, setImageErrors] = useState({});
  const [pagesReviewed, setPagesReviewed] = useState([]);
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [recommendationsData, setRecommendationsData] = useState([]);
  const [sortBy, setSortBy] = useState('page_url');
  const [sortDirection, setSortDirection] = useState('asc');
  const [filteredRecommendations, setFilteredRecommendations] = useState([]);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const { currentUser } = useAuth();
  const auth = getAuth();
  const [resolvedImageUrls, setResolvedImageUrls] = useState({});
  const [imagesLoaded, setImagesLoaded] = useState(false);

  useEffect(() => {
    const fetchReportData = async () => {
      if (report_id) {
        try {
          const timestamp = Date.now();
          const response = await fetch(`${API_BASE_URL}/api/v1/reports/${report_id}?t=${timestamp}`);
          if (!response.ok) throw new Error('Failed to fetch report');
          const data = await response.json();
          setReportData(data);

          const pagesResponse = await fetch(`${API_BASE_URL}/api/v1/reports/${report_id}/pages?t=${timestamp}`);
          if (!pagesResponse.ok) throw new Error('Failed to fetch pages');
          const pages = await pagesResponse.json();
          setPagesReviewed(pages || []);

          await fetchRecommendations(report_id);
        } catch (error) {
          console.error('Error fetching report data:', error);
          setError('Error fetching report data');
        } finally {
          setLoading(false);
        }
      }
    };

    const fetchRecommendations = async () => {
      if (report_id) {
        try {
          const timestamp = Date.now();
          const response = await fetch(`${API_BASE_URL}/api/v1/reports/${report_id}/summary?t=${timestamp}`);
          if (!response.ok) throw new Error('Failed to fetch recommendations');
          const data = await response.json();
          setRecommendationsData(data.recommendations);
          setFilteredRecommendations(data.recommendations);
        } catch (error) {
          console.error('Error fetching recommendations:', error);
        }
      }
    };

    fetchReportData();
  }, [report_id]);

  useEffect(() => {
    const resolveImageUrls = async (screenshots) => {
      const storage = getStorage();
      const resolvedUrls = {};
      for (const [pageUrl, imagePath] of Object.entries(screenshots)) {
        try {
          const imageRef = ref(storage, imagePath);
          const url = await getDownloadURL(imageRef);
          resolvedUrls[pageUrl] = url;
        } catch (error) {
          console.error(`Error fetching image for ${pageUrl}:`, error);
          resolvedUrls[pageUrl] = null; // Set to null if there's an error
        }
      }
      setResolvedImageUrls(resolvedUrls);
      setImagesLoaded(true);
    };

    if (reportData && reportData.screenshot_paths) {
      resolveImageUrls(reportData.screenshot_paths);
    }
  }, [reportData]);

  const handleDelete = async (report_id) => {
    if (window.confirm('Are you sure you want to delete this report? This will also delete all related pages.')) {
      try {
        const response = await fetch(`${API_BASE_URL}/api/v1/reports/${report_id}`, {
          method: 'DELETE',
        });
        if (!response.ok) throw new Error('Failed to delete report');
        navigate('/reports');
      } catch (error) {
        console.error('Error deleting report:', error);
        alert(`Error deleting report: ${error.message}`);
      }
    }
  };

  const handleImageError = (page_url) => {
    setImageErrors(prev => ({ ...prev, [page_url]: true }));
    console.error(`Failed to load image for ${page_url}`);
  };

  const handleSort = (column) => {
    const isAsc = sortBy === column && sortDirection === 'asc';
    setSortDirection(isAsc ? 'desc' : 'asc');
    setSortBy(column);
    const sortedRecommendations = [...filteredRecommendations].sort((a, b) => {
      if (isAsc) {
        return a[column] < b[column] ? -1 : 1;
      } else {
        return a[column] > b[column] ? -1 : 1;
      }
    });
    setFilteredRecommendations(sortedRecommendations);
  };

  const getFirstPageReviewed = () => {
    return pagesReviewed.length > 0 ? pagesReviewed[0] : null;
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const scrollToSection = (sectionId) => {
    setTimeout(() => {
      const element = document.getElementById(sectionId);
      if (element) {
        const headerOffset = 60; // Adjust this value based on your fixed header height
        const elementPosition = element.getBoundingClientRect().top;
        const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth'
        });
      }
      setMobileOpen(false); // Close the drawer after clicking
    }, 100); // Add a small delay to ensure the drawer closing animation doesn't interfere
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/login');
    } catch (error) {
      console.error('Failed to log out', error);
    }
  };

  const drawerContent = (
    <Box sx={{ width: 240, mt: 2, p: 2, display: 'flex', flexDirection: 'column', height: '100%' }}>
      <Link to="/" style={{ textDecoration: 'none' }}>
        <img src={HomeLogo} alt="Logo" style={{ maxWidth: '100%', marginBottom: '16px' }} />
      </Link>
      <Typography variant="h6" sx={{ mb: 2 }}>Report Sections</Typography>
      <Button variant="contained" href="/review" fullWidth sx={{ justifyContent: 'flex-start', mb: 1 }}>Review A Site</Button>
      <Button
        color="inherit"
        onClick={() => {
          scrollToSection('executive-summary');
          setMobileOpen(false); // Close the drawer after clicking
        }}
        fullWidth
        sx={{ justifyContent: 'flex-start', mb: 1 }}
      >
        Executive Summary
      </Button>
      <Button
        color="inherit"
        onClick={() => {
          scrollToSection('pages-reviewed');
          setMobileOpen(false); // Close the drawer after clicking
        }}
        fullWidth
        sx={{ justifyContent: 'flex-start', mb: 1 }}
      >
        Pages Reviewed
      </Button>
      <Button
        color="inherit"
        onClick={() => {
          scrollToSection('site-structure');
          setMobileOpen(false); // Close the drawer after clicking
        }}
        fullWidth
        sx={{ justifyContent: 'flex-start', mb: 1 }}
      >
        Site Structure
      </Button>
      <Button
        color="inherit"
        onClick={() => {
          scrollToSection('page-screenshots');
          setMobileOpen(false); // Close the drawer after clicking
        }}
        fullWidth
        sx={{ justifyContent: 'flex-start', mb: 1 }}
      >
        Page Screenshots
      </Button>
      <Button
        color="inherit"
        onClick={() => {
          scrollToSection('content-analysis');
          setMobileOpen(false); // Close the drawer after clicking
        }}
        fullWidth
        sx={{ justifyContent: 'flex-start', mb: 1 }}
      >
        Content Analysis
      </Button>
      <Button
        color="inherit"
        onClick={() => {
          scrollToSection('cta-analysis');
          setMobileOpen(false); // Close the drawer after clicking
        }}
        fullWidth
        sx={{ justifyContent: 'flex-start', mb: 1 }}
      >
        CTA Analysis
      </Button>
      <Button
        color="inherit"
        onClick={() => {
          scrollToSection('visual-analysis');
          setMobileOpen(false); // Close the drawer after clicking
        }}
        fullWidth
        sx={{ justifyContent: 'flex-start', mb: 1 }}
      >
        Visual Analysis
      </Button>
      <Button
        color="inherit"
        onClick={() => {
          scrollToSection('design-summary');
          setMobileOpen(false); // Close the drawer after clicking
        }}
        fullWidth
        sx={{ justifyContent: 'flex-start', mb: 1 }}
      >
        Design Summary
      </Button>
      <Button
        color="inherit"
        onClick={() => {
          scrollToSection('recommendations');
          setMobileOpen(false); // Close the drawer after clicking
        }}
        fullWidth
        sx={{ justifyContent: 'flex-start', mb: 1 }}
      >
        Recommendations
      </Button>

      {/* Spacer */}
      <Box sx={{ flexGrow: 1 }} />

      {/* Navigation items */}
      <Divider />
      <Button
        color="inherit"
        component={Link}
        to="/"
        onClick={() => setMobileOpen(false)} // Close the drawer after clicking
        sx={{ justifyContent: 'flex-start', mb: 1 }}
      >
        Home
      </Button>
      <Button
        color="inherit"
        component={Link}
        to="/dashboard"
        onClick={() => setMobileOpen(false)} // Close the drawer after clicking
        sx={{ justifyContent: 'flex-start', mb: 1 }}
      >
        Dashboard
      </Button>
      <Button
        color="inherit"
        component={Link}
        to="/pricing"
        onClick={() => setMobileOpen(false)} // Close the drawer after clicking
        sx={{ justifyContent: 'flex-start', mb: 1 }}
      >
        Pricing
      </Button>
      <Button
        color="inherit"
        component={Link}
        to="/faq"
        onClick={() => setMobileOpen(false)} // Close the drawer after clicking
        sx={{ justifyContent: 'flex-start', mb: 1 }}
      >
        FAQ
      </Button>
      {currentUser ? (
        <>
          <Button
            color="inherit"
            onClick={handleMenu}
            startIcon={
              currentUser.photoURL ? (
                <Avatar src={currentUser.photoURL} sx={{ width: 32, height: 32 }} />
              ) : (
                <AccountCircleIcon />
              )
            }
            sx={{ justifyContent: 'flex-start', mb: 1 }}
          >
            {currentUser.displayName || currentUser.email}
          </Button>
          <Menu
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={handleClose} component={Link} to="/profile">Profile</MenuItem>
            <MenuItem onClick={handleLogout}>Logout</MenuItem>
          </Menu>
        </>
      ) : (
        <Button
          color="inherit"
          component={Link}
          to="/login"
          onClick={() => setMobileOpen(false)} // Close the drawer after clicking
          sx={{ justifyContent: 'flex-start', mb: 1 }}
        >
          Login
        </Button>
      )}
    </Box>
  );

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Container maxWidth="md">
        <Typography variant="h5" color="error" align="center">{error}</Typography>
      </Container>
    );
  }

  if (!reportData) {
    return (
      <Container maxWidth="md">
        <Typography variant="h5" align="center">No report data available</Typography>
      </Container>
    );
  }

  const getScoreColor = (score) => {
    if (score >= 90) return 'success.main';
    if (score >= 80) return 'info.main';
    if (score >= 70) return 'warning.main';
    if (score >= 60) return 'semierror.main';
    return 'error.main';
  };

  const getScoreLabel = (score) => {
    if (score >= 90) return 'Excellent';
    if (score >= 80) return 'Great';
    if (score >= 70) return 'Good';
    if (score >= 60) return 'Fair';
    return 'Needs Improvement';
  };

  const scoreColor = getScoreColor(reportData.overall_score);
  const scoreLabel = getScoreLabel(reportData.overall_score);


  const capitalizeWord = (word) => {
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  };

  const handlePageClick = (pageUrl) => {
    console.log('Page clicked:', pageUrl);
    setIsModalOpen(true);
  };

  const getRecommendationCount = (valueType) => {
    return recommendationsData.filter(rec => rec.value === valueType).length;
  };

  return (
    <Box sx={{ display: 'flex' }}>
      {/* Mobile Drawer */}
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: { xs: 'block', sm: 'none' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: 240 },
        }}
      >
        {drawerContent}
      </Drawer>

      {/* Desktop Drawer */}
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: 'none', sm: 'block' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: 240 },
        }}
        open
      >
        {drawerContent}
      </Drawer>

      {/* Main content */}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: { xs: 1, sm: 2, md: 3 }, // Responsive padding
          width: { xs: '100%', sm: `calc(100% - 240px)` }, // Full width on mobile, adjust for desktop
          marginLeft: { xs: 0, sm: '240px' }, // No margin on mobile, adjust for desktop
        }}
      >
        {/* Fixed position burger menu for mobile */}
        <Box
          sx={{
            top: 16,
            left: 16,
            zIndex: 2200, // Increased z-index
            display: { xs: 'block', sm: 'none' },
            position: 'fixed', // Ensure it's fixed
          }}
        >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{
              backgroundColor: 'background.paper',
              boxShadow: 1,
              '&:hover': {
                backgroundColor: 'action.hover',
              },
            }}
          >
            <MenuIcon />
          </IconButton>
        </Box>

        {/* Add padding to the top of the content to prevent overlap with the fixed menu button */}
        <Box sx={{ pt: { xs: 7, sm: 0 } }}>
          <Container maxWidth="xl" sx={{ mt: { xs: 2, sm: 4 }, mb: 4 }}> {/* Changed to maxWidth="xl" for larger screens */}
            <Typography variant="h4" component="h1" gutterBottom sx={{ color: 'primary.main', fontSize: { xs: '1.5rem', sm: '2rem', md: '2.5rem' } }} align="center">
              Site Report
            </Typography>
            <Paper elevation={3} sx={{ p: { xs: 2, sm: 3 }, mb: 4, bgcolor: 'grey.900' }}>
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Button
                  variant="outlined"
                  onClick={() => navigate(-1)}
                  startIcon={<ArrowBackIcon />}
                  size="small"
                >
                  Back to Dashboard
                </Button>
                {/* <Button
                  variant="contained"
                  color="error"
                  size="small"
                  onClick={() => handleDelete(report_id)}
                  startIcon={<DeleteIcon />}
                >Delete</Button> */}
              </Box>

              <Divider sx={{ my: 2, bgcolor: 'grey.700' }} />
              <Grid container spacing={3}>
                <Grid item xs={12} md={4}>
                  <Box bgcolor="grey.800" p={2} borderRadius={1}>
                    <Box display="flex" justifyContent="space-between" mb={1}>
                      <Typography variant="h7" color="cyan">URL:</Typography>
                      <Link
                        href={reportData.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        color="primary"
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          maxWidth: '70%',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        <Typography variant="body2" sx={{ mr: 0.5 }}>
                          {reportData.url}
                        </Typography>
                        <OpenInNewIcon fontSize="small" />
                      </Link>
                    </Box>
                    <Divider sx={{ my: 2, bgcolor: 'grey.700' }} />
                    <Box display="flex" justifyContent="space-between" mb={1}>
                      <Typography variant="body2">Report Level:</Typography>
                      <Typography variant="body2">{capitalizeWord(reportData.report_level)}</Typography>
                    </Box>
                    <Box display="flex" justifyContent="space-between" mb={1}>
                      <Typography variant="body2">Pages Reviewed:</Typography>
                      <Typography variant="body2">{reportData.page_count}</Typography>
                    </Box>
                    <Box display="flex" justifyContent="space-between" mb={1}>
                      <Typography variant="body2">Report Runtime:</Typography>
                      <Typography variant="body2">{reportData.report_runtime} second(s)</Typography>
                    </Box>
                    <Box display="flex" justifyContent="space-between">
                      <Typography variant="body2">Date:</Typography>
                      <Typography variant="body2">{formatDate(reportData.created_at)}</Typography>
                    </Box>
                  </Box>
                </Grid>

                <Grid item xs={12} md={4}>
                  <Box bgcolor="grey.800" p={2} borderRadius={1} height="100%" display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                    <Typography variant="h7" gutterBottom>
                      Overall Score {reportData.overall_score > 80 && (
                        <ThumbUpIcon sx={{ verticalAlign: 'bottom', color: scoreColor }} />
                      )}
                      <Divider sx={{ my: 2, bgcolor: 'grey.700' }} />
                    </Typography>
                    <Box position="relative" display="flex" alignItems="center" justifyContent="center" mb={1}>
                      <CircularProgress
                        variant="determinate"
                        value={reportData.overall_score}
                        size={80}
                        thickness={4}
                        sx={{ color: scoreColor }}
                      />
                      <Box
                        position="absolute"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Typography variant="h5" component="div" sx={{ color: scoreColor }}>
                          {`${Math.round(reportData.overall_score)}%`}
                        </Typography>
                      </Box>
                    </Box>
                    <Typography variant="body1" sx={{ color: scoreColor }}>
                      {scoreLabel}
                    </Typography>
                  </Box>
                </Grid>

                <Grid item xs={12} md={4}>
                  <Box bgcolor="grey.800" p={2} borderRadius={1}>
                    <Typography variant="h7" gutterBottom>
                      🖼️&nbsp;Per Page Report
                      <Divider sx={{ my: 2, bgcolor: 'grey.700' }} />
                      <Box display="flex" justifyContent="center">
                        <Button variant="contained" color="primary" onClick={() => handlePageClick(getFirstPageReviewed.url)}>View Per Page Report</Button>
                      </Box>
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Box bgcolor="grey.800" p={2} borderRadius={1}>
                    📄 Report Level - <span style={{ color: 'orange' }}>{reportData.report_level}</span>{reportData.report_level === 'Basic' && (<span style={{ color: 'text.secondary' }}>*</span>)}
                    <Divider sx={{ my: 2, bgcolor: 'grey.700' }} />
                    <Box display="flex" justifyContent="space-between" ml={3} mb={1}>
                      <Typography variant="body2">
                        Max Pages:
                      </Typography>
                      <Typography variant="body2">{reportData.max_pages_per_report}</Typography>
                    </Box>
                    <Box display="flex" justifyContent="space-between" ml={3} mb={1}>
                      <Typography variant="body2">
                        Max Depth:
                      </Typography>
                      <Typography variant="body2">{reportData.max_depth}</Typography>
                    </Box>
                    <Box display="flex" justifyContent="space-between" ml={3} mb={1}>
                      <Typography variant="body2">
                        Max Recommendations:
                      </Typography>
                      <Typography variant="body2">{reportData.max_recommendations}</Typography>
                    </Box>
                    <Box display="flex" justifyContent="space-between" ml={3} mb={1}>
                    {reportData.report_level === 'Basic' && (
                      <Typography variant="body2" sx={{ fontStyle: 'italic', fontSize: '12px' }}>
                        *Use Pro or Plus report level for more pages & recommendations. <Link to="/pricing" style={{ color: 'inherit', textDecoration: 'underline' }}>Upgrade</Link>
                      </Typography>
                    )}
                    </Box>
                  </Box>
                </Grid>








                <Grid item xs={12} md={4}>
                  <Box bgcolor="grey.800" p={2} borderRadius={1}>
                    <Typography variant="h7" gutterBottom>
                      📊 Scores
                    </Typography>
                    <Divider sx={{ my: 2, bgcolor: 'grey.700' }} />
                    <Box display="flex" justifyContent="space-between" ml={3} mb={1}>
                      <Typography variant="body2">
                        Content Score:
                      </Typography>
                      <Typography variant="body2">{reportData.average_content_score}</Typography>

                    </Box>
                    <Box display="flex" justifyContent="space-between" ml={3} mb={1}>
                      <Typography variant="body2">
                        CTA Score:
                      </Typography>
                      <Typography variant="body2">{reportData.average_cta_score}</Typography>
                    </Box>
                    <Box display="flex" justifyContent="space-between" ml={3} mb={1}>
                      <Typography variant="body2">
                        Design Score:
                      </Typography>
                      <Typography variant="body2">{reportData.average_design_score}</Typography>
                    </Box>
                    <Box display="flex" justifyContent="space-between" ml={3} mb={1}>
                      <Typography variant="body2">
                        Visual Score:
                      </Typography>
                      <Typography variant="body2">{reportData.average_visual_score}</Typography>
                    </Box>
                  </Box>
                </Grid>

                <Grid item xs={12} md={4}>
                  <Box bgcolor="grey.800" p={2} borderRadius={1}>
                    <Typography variant="h7" gutterBottom>
                      📝 Recommendations:
                    </Typography>
                    <Divider sx={{ my: 2, bgcolor: 'grey.700' }} />
                    <Box display="flex" justifyContent="space-between" ml={3} mb={1}>
                      <Typography variant="body2">
                        Total Identified:
                      </Typography>
                      <Typography variant="body2">{recommendationsData.length}</Typography>
                    </Box>

                    <Box display="flex" justifyContent="space-between" ml={5} mb={1}>
                      <Typography variant="body2">
                        High Value:
                      </Typography>
                      <Typography variant="body2">{getRecommendationCount('High') + getRecommendationCount('high')}</Typography>
                    </Box>
                    <Box display="flex" justifyContent="space-between" ml={5} mb={1}>
                      <Typography variant="body2">
                        Medium Value:
                      </Typography>
                      <Typography variant="body2">{getRecommendationCount('Medium') + getRecommendationCount('medium') + getRecommendationCount('Med') + getRecommendationCount('med') + getRecommendationCount('Mid') + getRecommendationCount('mid')}</Typography>
                    </Box>
                    <Box display="flex" justifyContent="space-between" ml={5} mb={1}>
                      <Typography variant="body2">
                        Low Value:
                      </Typography>
                      <Typography variant="body2">{getRecommendationCount('Low') + getRecommendationCount('low')}</Typography>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Paper>

            {/* Report Content Below */}
            <Grid container spacing={{ xs: 2, sm: 3, md: 4 }}>

              <Grid item xs={12} md={12} id="executive-summary">
                <Grid container spacing={4}>
                  <Grid item xs={12}>
                    <Paper elevation={3} sx={{ p: { xs: 2, sm: 3 }, mb: 4 }}>
                      <Typography variant="h6" gutterBottom>
                        🎯 Executive Summary
                      </Typography>
                      <Divider sx={{ my: 2 }} />
                      <ReactMarkdown>{reportData.executive_summary}</ReactMarkdown>
                    </Paper>
                  </Grid>
                </Grid>
              </Grid>


              <Grid item xs={12} md={12} id="pages-reviewed">
                <Paper elevation={3} sx={{ p: { xs: 2, sm: 3 }, mb: 4, bgcolor: 'grey.900' }}>
                  <Typography variant="h6" gutterBottom>
                    🔎 Pages Reviewed
                  </Typography>
                  <Divider sx={{ my: 2 }} />
                  <Typography variant="body2" gutterBottom>(Click to view the report for each page)</Typography>
                  <List>
                    {pagesReviewed.map((page, index) => (
                      <ListItem key={index} disablePadding>
                        <ListItemText
                          primary={
                            <MuiLink
                              href='#'
                              onClick={(e) => {
                                e.preventDefault();
                                handlePageClick(page.url);
                              }}
                              style={{ color: 'primary.main', textDecoration: 'none' }}
                            >
                              {page.url}
                            </MuiLink>
                          }
                        />
                      </ListItem>
                    ))}
                  </List>
                </Paper>
              </Grid>
              <Grid item xs={12} id="site-structure">
                {reportData.mermaid_diagram && (
                  <Paper elevation={3} sx={{ p: { xs: 2, sm: 3 }, mb: 4 }}>
                    <Typography variant="h6" gutterBottom>
                      🌐 Site Structure Crawled
                    </Typography>
                    <MermaidDiagram diagram={reportData.mermaid_diagram} />
                  </Paper>
                )}
              </Grid>

              <Grid item xs={12} id="page-screenshots">
                <Paper elevation={3} sx={{ p: { xs: 2, sm: 3 }, mb: 4 }}>
                  <Typography variant="h6" gutterBottom>
                    📷 Page Screenshots
                  </Typography>
                  <Divider sx={{ my: 2 }} />
                  {!imagesLoaded ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 200 }}>
                      <CircularProgress />
                    </Box>
                  ) : reportData.screenshot_paths && Object.keys(reportData.screenshot_paths).length > 0 ? (
                    <ImageList sx={{ width: '100%', height: 'auto' }} cols={{ xs: 1, sm: 2, md: 3, lg: 4 }} gap={16}>
                      {Object.entries(reportData.screenshot_paths).map(([page_url, screenshot_path]) => (
                        <ImageListItem key={page_url}>
                          {imageErrors[page_url] ? (
                            <Box sx={{ height: 200, display: 'flex', alignItems: 'center', justifyContent: 'center', bgcolor: 'grey.300' }}>
                              <Typography variant="body2">Failed to load image</Typography>
                            </Box>
                          ) : (
                            <img
                              src={resolvedImageUrls[page_url] || ''}
                              alt={`Screenshot of ${page_url}`}
                              loading="lazy"
                              onError={() => handleImageError(page_url)}
                              style={{ width: '100%', height: '200px', objectFit: 'cover' }}
                            />
                          )}
                          <ImageListItemBar
                            title={
                              <Typography noWrap>
                                {page_url}
                              </Typography>
                            }
                            actionIcon={
                              <IconButton
                                sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                                aria-label={`info about ${page_url}`}
                                onClick={() => setOpenScreenshot(resolvedImageUrls[page_url])}
                              >
                                <FullscreenIcon />
                              </IconButton>
                            }
                          />
                        </ImageListItem>
                      ))}
                    </ImageList>
                  ) : (
                    <Typography>No screenshots available</Typography>
                  )}
                </Paper>
              </Grid>
              <Grid item xs={12} id="content-analysis">
                <Paper elevation={3} sx={{ p: { xs: 2, sm: 3 }, mb: 4 }}>
                  <Typography variant="h6">
                    📚 Content Analysis
                  </Typography>
                  <Divider sx={{ my: 2 }} />
                  <ReactMarkdown>{reportData.content_summary}</ReactMarkdown>
                </Paper>
              </Grid>
              <Grid item xs={12} id="cta-analysis">
                <Paper elevation={3} sx={{ p: { xs: 2, sm: 3 }, mb: 4 }}>
                  <Typography variant="h6">
                    💼 Call-to-Action (CTA) Analysis
                  </Typography>
                  <Divider sx={{ my: 2 }} />
                  <ReactMarkdown>{reportData.cta_summary}</ReactMarkdown>
                </Paper>
              </Grid>
              <Grid item xs={12} id="visual-analysis">
                <Paper elevation={3} sx={{ p: { xs: 2, sm: 3 }, mb: 4 }}>
                  <Typography variant="h6">
                    🎨 Visual Analysis
                  </Typography>
                  <Divider sx={{ my: 2 }} />
                  <ReactMarkdown>{reportData.visual_summary}</ReactMarkdown>
                </Paper>
              </Grid>
              <Grid item xs={12} id="design-summary">
                <Paper elevation={3} sx={{ p: { xs: 2, sm: 3 }, mb: 4 }}>
                  <Typography variant="h6">
                    🚀 Design Summary
                  </Typography>
                  <Divider sx={{ my: 2 }} />
                  <ReactMarkdown>{reportData.design_summary}</ReactMarkdown>
                </Paper>
              </Grid>
              <Grid item xs={12} id="recommendations">
                <Paper elevation={3} sx={{ p: { xs: 2, sm: 3 }, mb: 4 }}>
                  <Typography variant="h6" gutterBottom>
                    📝 Actionable Recommendations
                  </Typography>
                  <Divider sx={{ my: 2 }} />
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <TableSortLabel
                            active={sortBy === 'page_url'}
                            direction={sortDirection}
                            onClick={() => handleSort('page_url')}
                          >
                            Page URL
                          </TableSortLabel>
                        </TableCell>
                        <TableCell>
                          <TableSortLabel
                            active={sortBy === 'title'}
                            direction={sortDirection}
                            onClick={() => handleSort('title')}
                          >
                            Title
                          </TableSortLabel>
                        </TableCell>
                        <TableCell>
                          <TableSortLabel
                            active={sortBy === 'action_description'}
                            direction={sortDirection}
                            onClick={() => handleSort('action_description')}
                          >
                            Action Description
                          </TableSortLabel>
                        </TableCell>
                        <TableCell>
                          <TableSortLabel
                            active={sortBy === 'effort'}
                            direction={sortDirection}
                            onClick={() => handleSort('effort')}
                          >
                            Effort
                          </TableSortLabel>
                        </TableCell>
                        <TableCell>
                          <TableSortLabel
                            active={sortBy === 'impact'}
                            direction={sortDirection}
                            onClick={() => handleSort('impact')}
                          >
                            Impact
                          </TableSortLabel>
                        </TableCell>
                        <TableCell>
                          <TableSortLabel
                            active={sortBy === 'value'}
                            direction={sortDirection}
                            onClick={() => handleSort('value')}
                          >
                            Value
                          </TableSortLabel>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {filteredRecommendations.map((recommendation, index) => (
                        <TableRow key={index}>
                          <TableCell sx={{ display: { xs: 'none', sm: 'table-cell' } }}>{recommendation.page_url || 'N/A'}</TableCell>
                          <TableCell>{recommendation.title || 'N/A'}</TableCell>
                          <TableCell>{recommendation.action_description || 'No summary available'}</TableCell>
                          <TableCell sx={{ display: { xs: 'none', md: 'table-cell' } }}>{recommendation.effort || 'No summary available'}</TableCell>
                          <TableCell sx={{ display: { xs: 'none', md: 'table-cell' } }}>{recommendation.impact || 'No impact specified'}</TableCell>
                          <TableCell>{recommendation.value || 'No value provided'}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Paper>
              </Grid>
            </Grid>

            <Dialog
              open={!!openScreenshot}
              onClose={() => setOpenScreenshot(null)}
              maxWidth="lg"
              fullWidth
            >
              <DialogTitle>📷 Screenshot Preview</DialogTitle>
              <DialogContent>
                {openScreenshot && (
                  <img src={openScreenshot} alt="Full size screenshot" style={{ width: '100%', height: 'auto' }} />
                )}
              </DialogContent>
            </Dialog>
            {pagesReviewed.length > 0 && (
              <PageReviewModal
                open={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                pages={pagesReviewed}
              />
            )}


          </Container>
        </Box>
      </Box>
    </Box>
  );
};

export default Report;