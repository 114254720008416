import React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';

const tutorials = [
    {
        title: 'Getting Started',
        description: 'A 37 second demonstration of how to run a website review.',
        videoSrc: 'https://www.youtube.com/embed/GjUMEL5R9V8', // Updated to YouTube embed link
    },
    // Add more tutorials as needed
];

export default function TutorialsAndDemos() {
    return (
        <Box
            align="center"
            sx={{
                bgcolor: 'background.default',
                py: { xs: 4, md: 6 },
                color: 'text.primary',
            }}
        >
            <Container maxWidth="lg">
                <Typography
                    component="h4"
                    variant="h4"
                    textAlign="center"
                    mb={{ xs: 4, md: 6 }}
                >
                    View a Short Demo
                </Typography>
                <Box display="flex" justifyContent="center">
                    <Grid container spacing={4} maxWidth="md">
                        {tutorials.map((tutorial, index) => (
                            <Grid item xs={12} key={index}>
                                <Card 
                                    sx={{ 
                                        height: '100%', 
                                        display: 'flex', 
                                        flexDirection: 'column',
                                        boxShadow: '0 0 10px 5px rgba(25, 118, 210, 1)', // Add glowing effect
                                        transition: 'box-shadow 0.3s ease-in-out', // Smooth transition for hover effect
                                        '&:hover': {
                                            boxShadow: '0 0 15px 8px rgba(0, 123, 255, 0.7)', // Intensify glow on hover
                                        },
                                    }}
                                >
                                    <CardMedia
                                        component="iframe"
                                        sx={{ 
                                            width: '100%', // Set width to 100% for responsiveness
                                            height: 'auto', // Maintain aspect ratio
                                            aspectRatio: '16 / 9', // Set aspect ratio to 16:9
                                        }}
                                        src={tutorial.videoSrc}
                                        controls
                                        allowFullScreen
                                    />
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            </Container>
        </Box>
    );
}
