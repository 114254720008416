import ReviewForm from '../components/ReviewForm';
import Footer from '../components/Footer';
import { Box, Divider } from '@mui/material';


export default function ReviewPage() {
  return (
    <Box sx={{ bgcolor: 'background.default' }}>
      <ReviewForm />
      <Divider />
      <Footer />
    </Box>
  );
}
