import React, { useState, useEffect } from 'react';
import { API_BASE_URL } from '../config';
import { TextField, Button, Box, Select, MenuItem, FormControl, InputLabel, Alert, CircularProgress, Container, Typography, Paper } from '@mui/material';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import validUrl from 'valid-url';
import { useAuth } from '../AuthContext';
import TerminalProgress from './TerminalProgress';
import HomeLogo from "../static/img/Transparent_Logo_symbol.svg";
import axios from 'axios';
import { useMediaQuery } from '@mui/material';

const ReviewForm = () => {
  const { currentUser } = useAuth();
  const [profileDetails, setProfileDetails] = useState({});
  const [authError, setAuthError] = useState(false);
  const [urlError, setUrlError] = useState('');
  const [url, setUrl] = useState('');
  const [llm, setLlm] = useState('gemini1.5flash');
  const [report_level, setReportLevel] = useState('Basic');
  const [loading, setLoading] = useState(false);
  const [crawlStatus, setCrawlStatus] = useState([]);
  const [isCrawling, setIsCrawling] = useState(false);
  const [progress, setProgress] = useState(0);
  const [subscriptionError, setSubscriptionError] = useState('');
  const [generalError, setGeneralError] = useState('');
  const navigate = useNavigate();

  const isDevEnvironment = process.env.REACT_APP_ENV === 'dev';


  // ========================================================================== //
  //   Get the user's profile details from the database
  // ========================================================================== //

  useEffect(() => {
    if (!currentUser) {
      setAuthError(true); // Set authError if no user is authenticated
      return;
    }
    setAuthError(false); // Clear authError if user is authenticated
    const fetchProfileDetails = async () => {
      if (currentUser) {
        try {
          const response = await axios.get(`${API_BASE_URL}/api/v1/users/${currentUser.uid}`);
          const userData = response.data;
          setProfileDetails(userData);
        } catch (error) {
          console.error('Error fetching profile details:', error);
        }
      }
    };

    fetchProfileDetails();
  }, [currentUser]);


  // ========================================================================== //
  //   Set the default report_level based on the user's subscription tier
  // ========================================================================== //

  useEffect(() => {
    setReportLevel(profileDetails?.subscription_tier || 'Basic');
  }, [profileDetails]);

  // ========================================================================== //
  // Check if the URL starts with http or https and append if necessary
  // ========================================================================== //

  const validateUrl = (inputUrl) => {
    let processedUrl = inputUrl.trim();
  
    if (!/^https?:\/\//i.test(processedUrl)) {
      processedUrl = 'http://' + processedUrl;
    }
  
    try {
      new URL(processedUrl);
      setUrl(processedUrl); // Update the url state
      return processedUrl; // Return the processed URL
    } catch (e) {
      console.error('Error parsing URL:', e);
      setUrl(''); // Clear the url state if it's invalid
      return false;
    }
  };

  // ========================================================================== //
  //   Handle the form submission
  // ========================================================================== //

  const handleSubmit = async (event) => {
    event.preventDefault();
  
    const processedUrl = validateUrl(url);
    if (!processedUrl) {
      setUrlError('Invalid URL format. Please enter a valid URL.');
      return;
    }
    setUrlError('');
  
    setLoading(true);
    setIsCrawling(true);
    setCrawlStatus([]);
    setProgress(0);
    setSubscriptionError('');
    setGeneralError('');
  
    try {
      const response = await axios.post(`${API_BASE_URL}/api/v1/analyze`, {
        url: processedUrl,
        llm,
        report_level,
        user_id: currentUser.uid
      });

      // ========================================================================== //
      //   Set up the EventSource to receive crawl status updates
      // ========================================================================== //

      const eventSource = new EventSource(`${API_BASE_URL}/api/v1/crawl_status?report_id=${response.data.report_id}&user_id=${currentUser.uid}`);

      eventSource.onmessage = (event) => {
        const data = JSON.parse(event.data);
        console.log('Received event:', data);

        setCrawlStatus(prevStatus => [...prevStatus, data]);

        // Set progress based on different status messages
        switch (data.status.toLowerCase()) {
          case 'started':
            setProgress(5);
            break;
          case 'in progress':
            setProgress(10);
            break;
          case 'crawling page':
            setProgress(20);
            break;
          case 'llm vision analysis':
            setProgress(30);
            break;
          case 'vision llm analyzing screenshot':
            setProgress(40);
            break;
          case 'llm executive summary':
            setProgress(50);
            break;
          case 'llm category analysis: design':
            setProgress(60);
            break;
          case 'llm category analysis: content':
            setProgress(70);
            break;
          case 'llm category analysis: cta':
            setProgress(80);
            break;
          case 'llm category analysis: visual':
            setProgress(90);
            break;
          case 'generating final report':
            setProgress(95);
            break;
          case 'completed':
            setProgress(100);
            eventSource.close();
            setIsCrawling(false);
            navigate(`/report?url=${encodeURIComponent(url)}&llm=${llm}&report_level=${report_level}&report_id=${response.data.report_id}`);
            break;
          case 'error':
            setGeneralError('An error occurred during the review process. ' + data.message || 'An error occurred during the review process.');
            setLoading(false);
            setIsCrawling(false);
            eventSource.close();
            break;
          case 'timeout':
            setGeneralError('The review process timed out. Please try again later.');
            setLoading(false);
            setIsCrawling(false);
            eventSource.close();
            break;
          default:
            console.log(`Unhandled status: ${data.status}`);
            break;
        }
      };

    } catch (error) {
      if (error.response && error.response.status === 403) {
        setSubscriptionError('You have reached the report limit for your subscription tier.');
      } else {
        console.error('Error starting review:', error);
        setGeneralError('An error occurred while starting the review. Please try again.');
      }
      setLoading(false);
      setIsCrawling(false);
    }
  };


  const handleUpgradeClick = () => {
    navigate('/pricing');
  };


  // Detect if the device is mobile
  const isMobile = useMediaQuery('(max-width:600px)'); // Adjust the breakpoint as needed

  return (
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        pt: { xs: 6, sm: 6, md: 10, lg: 10 },
        pb: { xs: 6, sm: 6, md: 8, lg: 8 },
      }}>

      <img src={HomeLogo} alt="Home Logo" style={{ maxWidth: isMobile ? '100px' : '125px', marginBottom: isMobile ? '10px' : '20px' }} />
      <Typography variant="h2" sx={{ marginBottom: isMobile ? '5px' : '10px', fontSize: isMobile ? '1.5rem' : null }}>Site Review Desk</Typography>
      <Typography variant="h5" sx={{
        color: 'primary.main',
        fontStyle: 'italic',
        marginBottom: isMobile ? '10px' : '20px',
        fontSize: isMobile ? '1rem' : null,
      }}>AI-Powered Website Reviews</Typography>

      {authError && (
        <Alert severity="warning" sx={{ mb: 2 }}>
          You need to log in or create an account to run a review.{' '}
          <Link to="/login" style={{ color: '#58dbef', textDecoration: 'underline' }}>
            Click here to log in
          </Link>
        </Alert>
      )}

      {subscriptionError && (
        <Alert
          severity="warning"
          sx={{ mb: 2, width: '100%', maxWidth: 600 }}
          action={
            <Button color="inherit" size="small" onClick={handleUpgradeClick} variant="contained">
              Upgrade
            </Button>
          }
        >
          {subscriptionError} Please upgrade your subscription to continue.
        </Alert>
      )}
      {generalError && (
        <Alert severity="error" sx={{ mb: 2, width: '100%', maxWidth: 600 }}>
          {generalError}
        </Alert>
      )}
      <>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1, width: '100%', maxWidth: 600 }}>
          {profileDetails.remaining_reports === 0 && (
            <Alert severity="warning" sx={{ mb: 2 }}>
              You've reached the maximum number of reports runs you can run this month for your subscription tier.
            </Alert>
          )}
          {profileDetails.remaining_reports === 0 && profileDetails.subscription_tier !== 'Pro' && (
            <Box display="flex" justifyContent="center" mt={2} mb={2}>
              <Button variant="contained" size="small" onClick={() => navigate('/pricing')}>
                Upgrade
              </Button>
            </Box>
          )}
          <TextField
            margin="normal"
            required
            fullWidth
            id="url"
            label="Website URL (e.g. http://alvorithm.com)"
            name="url"
            autoFocus
            value={url}
            onChange={(e) => setUrl(e.target.value)}
            error={!!urlError}
            helperText={urlError || 'Enter a valid URL'}
            disabled={loading || isCrawling || profileDetails.remaining_reports === 0}
          />
          {isDevEnvironment && (
            <FormControl fullWidth margin="normal">
              <InputLabel id="llm-select-label">Select LLM</InputLabel>
              <Select
                labelId="llm-select-label"
                id="llm-select"
                value={llm}
                label="Select LLM"
                onChange={(e) => setLlm(e.target.value)}
                disabled={loading || isCrawling || profileDetails.remaining_reports === 0}
              >
                <MenuItem value="chatgpt4.0mini">ChatGPT 4o-mini</MenuItem>
                <MenuItem value="sonnet3.5">Claude Sonnet 3.5</MenuItem>
                <MenuItem value="gemini1.5flash">Gemini 1.5 Flash</MenuItem>
                <MenuItem value="ollama">Ollama</MenuItem>
              </Select>
            </FormControl>
          )}
          <FormControl fullWidth margin="normal">
            <InputLabel id="report-level-select-label" >Report Level</InputLabel>
            <Select
              labelId="report-level-select-label"
              id="report-level-select"
              value={report_level}
              label="Report Level"
              onChange={(e) => setReportLevel(e.target.value)}
              disabled={loading || isCrawling || profileDetails.remaining_reports === 0}
            >
              <MenuItem value="Basic">Basic</MenuItem>
              <MenuItem value="Plus" disabled={!['Plus', 'Pro'].includes(profileDetails.subscription_tier)}>
                Plus {!['Plus', 'Pro'].includes(profileDetails.subscription_tier) && '(requires upgrade)'}
              </MenuItem>
              <MenuItem value="Pro" disabled={profileDetails.subscription_tier !== 'Pro'}>
                Pro {profileDetails.subscription_tier !== 'Pro' && '(requires upgrade)'}
              </MenuItem>
            </Select>
          </FormControl>

          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            disabled={loading || isCrawling || profileDetails.remaining_reports === 0}
          >
            {loading || isCrawling ? (
              <CircularProgress size={24} sx={{ color: 'white', mr: 1 }} />
            ) : 'Start Review'}
          </Button>
        </Box>

        {isCrawling && (
          <>
            <Paper
              elevation={0}
              sx={{
                mt: 2,
                mb: 2,
                p: 2,
                borderRadius: 2,
                border: '1px solid',
                color: 'grey.800',
                width: '100%',
                maxWidth: 600, // Match the maxWidth of the form
              }}
            >
              <Typography variant="body1" color="info.dark">
                Your review is underway! It typically takes 2-5 minutes to complete.
                Feel free to watch the progress below or check your report later in the{' '}
                <Link to="/dashboard" style={{ color: '#58dbef', textDecoration: 'underline' }}>
                  Dashboard
                </Link>.
              </Typography>
            </Paper>
            <Box
              mt={4}
              width="100%"
              maxWidth={600}
              sx={{
                opacity: 0,
                transition: 'opacity 0.5s ease-in',
                '&.visible': {
                  opacity: 1,
                },
              }}
              className="visible"
            >
              <TerminalProgress progress={progress} events={crawlStatus} />
            </Box>
          </>
        )}
      </>
    </Container>
  );
};

export default ReviewForm;