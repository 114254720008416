import React, { useEffect, useState } from 'react';
import { Container, Typography, Button, Box, Card, CardContent, List, ListItem, ListItemIcon, ListItemText, ToggleButtonGroup, ToggleButton } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import CheckoutForm from './CheckoutForm';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../AuthContext';
import CircularProgress from '@mui/material/CircularProgress';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const PricingCard = ({ title, hintTitle, description, price, discountedPrice, features, buttonText, isPopular, product, children, pricingPeriod }) => (
  <Card raised={isPopular} sx={{ height: '100%', display: 'flex', flexDirection: 'column', position: 'relative' }}>
    <CardContent sx={{ flexGrow: 1, pb: 0 }}>
      <Typography variant="subtitle2" color={isPopular ? 'primary.main' : 'text.secondary'} gutterBottom>
        {hintTitle}
      </Typography>
      <Typography variant="h5" component="h2" gutterBottom>
        {title}
      </Typography>
      <Typography variant="subtitle1" color="text.secondary" paragraph>
        {description}
      </Typography>
      <Typography variant="h4" component="div" gutterBottom>
        ${price}
        <Typography variant="caption">/{pricingPeriod === 'yearly' ? 'yr' : 'mo'}</Typography>
      </Typography>
      <List>
        {features.map((feature, index) => (
          <ListItem key={index} disablePadding>
            <ListItemIcon>
              <CheckIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary={feature} />
          </ListItem>
        ))}
      </List>
      {children}
    </CardContent>
  </Card>
);

const Pricing = () => {
  const [pricingPeriod, setPricingPeriod] = useState('monthly');
  const [userInfo, setUserInfo] = useState(null);
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const couponCode = "INTRO20";
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchUserInfo = async () => {
      if (currentUser) {
        setIsLoading(true);
        try {
          const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/user_info?user_id=${currentUser.uid}`);
          setUserInfo(response.data);
        } catch (error) {
          console.error('Error fetching user info:', error);
        } finally {
          setIsLoading(false);
        }
      } else {
        setIsLoading(false);
      }
    };

    fetchUserInfo();
  }, [currentUser]);

  const handlePricingPeriodChange = (event, newPricingPeriod) => {
    if (newPricingPeriod !== null) {
      setPricingPeriod(newPricingPeriod);
    }
  };

  const calculateYearlyPrice = (monthlyPrice) => {
    return (monthlyPrice * 10).toFixed(2);
  };

  const calculateDiscountedPrice = (price) => {
    return (price * 0.5).toFixed(2);
  };

  const renderPricingButton = (tier) => {
    if (!currentUser) {
      return (
        <Button
          fullWidth
          variant="outlined"
          color="primary"
          onClick={() => navigate('/login')}
        >
          Sign In to Subscribe
        </Button>
      );
    }

    const lowerTier = tier.toLowerCase();
    const currentTier = userInfo?.subscription_tier.toLowerCase();

    if (currentTier === lowerTier) {
      return (
        <Button fullWidth variant="contained" color="success" disabled>
          Current Plan
        </Button>
      );
    }

    if (lowerTier === 'basic') {
      return (
        <Button
          fullWidth
          variant="outlined"
          color="primary"
          onClick={() => navigate('/review')}
        >
          Try Now
        </Button>
      );
    }

    let upgradeText = "Purchase Now";
    if (currentTier) {
      if ((currentTier === 'basic') || (currentTier === 'plus' && lowerTier === 'pro')) {
        upgradeText = `Upgrade to ${tier}`;
      } else if (currentTier === 'pro' && lowerTier === 'plus') {
        upgradeText = `Downgrade to ${tier}`;
      }
    }

    const priceId = {
      plus: {
        monthly: process.env.REACT_APP_STRIPE_PLUS_MONTHLY_PRICE_ID,
        yearly: process.env.REACT_APP_STRIPE_PLUS_YEARLY_PRICE_ID
      },
      pro: {
        monthly: process.env.REACT_APP_STRIPE_PRO_MONTHLY_PRICE_ID,
        yearly: process.env.REACT_APP_STRIPE_PRO_YEARLY_PRICE_ID
      }
    };

    return (
      <CheckoutForm
        text={upgradeText}
        product={{
          price: priceId[lowerTier][pricingPeriod],
          quantity: 1
        }}
      />
    );
  };

  if (isLoading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '100vh',
          bgcolor: '#06090a',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box
      id="pricing"
      sx={{
        color: 'white',
        bgcolor: '#06090a',
      }}
    >
      <Container id="pricing" sx={{ pt: 6, pb: 8, xs: 8, sm: 16 }}>
        <Typography component="h2" variant="h4" color="text.primary" align="center" mb={2}>
          Pricing
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'center', mb: 4 }}>
          <ToggleButtonGroup
            value={pricingPeriod}
            exclusive
            onChange={handlePricingPeriodChange}
            aria-label="pricing period"
          >
            <ToggleButton value="monthly" aria-label="monthly">
              MONTHLY
            </ToggleButton>
            <ToggleButton value="yearly" aria-label="yearly">
              YEARLY (2 MONTHS FREE)
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 4 }}>
          <Box sx={{ flex: '1 1 300px' }}>
            <PricingCard
              hintTitle="Basic"
              title="Basic"
              description="For individuals and small businesses looking to get started"
              price={pricingPeriod === 'monthly' ? '0' : '0'}
              features={[
                "5 free report runs per month",
                "5 pages crawled per report",
                "Max link depth of 2",
                "Up to 3 recommendations per page",
              ]}
              pricingPeriod={pricingPeriod}
            >
              {renderPricingButton('basic')}
            </PricingCard>
          </Box>
          <Box sx={{ flex: '1 1 300px' }}>
            <Elements stripe={stripePromise}>
              <PricingCard
                hintTitle='Most Popular'
                title="Plus"
                description="For growing businesses and marketing professionals"
                price={pricingPeriod === 'monthly' ? '9.99' : calculateYearlyPrice(9.99)}
                discountedPrice={pricingPeriod === 'monthly' ? calculateDiscountedPrice(9.99) : calculateDiscountedPrice(calculateYearlyPrice(9.99))}
                features={[
                  "50 report runs per month",
                  "10 pages crawled per report",
                  "Max link depth of 4",
                  "Up to 20 recommendations per page",
                ]}
                product={process.env.REACT_APP_STRIPE_PLUS_PRODUCT_ID}
                isPopular
                pricingPeriod={pricingPeriod}
              >
                {renderPricingButton('plus')}
              </PricingCard>
            </Elements>
          </Box>
          <Box sx={{ flex: '1 1 300px' }}>
            <Elements stripe={stripePromise}>
              <PricingCard
                hintTitle='Full Features'
                title="Pro"
                description="For agencies, consultants, and larger organizations"
                price={pricingPeriod === 'monthly' ? '19.99' : calculateYearlyPrice(19.99)}
                discountedPrice={pricingPeriod === 'monthly' ? calculateDiscountedPrice(19.99) : calculateDiscountedPrice(calculateYearlyPrice(19.99))}
                features={[
                  "500 report runs per month",
                  "50 pages crawled per report",
                  "Max link depth of 6",
                  "Up to 100 recommendations per page",
                  "Priority support"
                ]}
                product={process.env.REACT_APP_STRIPE_PRO_PRODUCT_ID}
                pricingPeriod={pricingPeriod}
              >
                {renderPricingButton('pro')}
              </PricingCard>
            </Elements>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Pricing;